import stageAddressImage from './stages/address.jpg';
import stageCFDImage from './stages/cfd.jpg';
import stageCorporateImage from './stages/corporate.jpg';
import stageEnhancedImage from './stages/enhanced.jpg';
import stageIdentityImage from './stages/identity.jpg';
import mobileAppQR from './mobile-app-qr.png';
import logo from './logo.svg';

export const images = {
  stages: {
    identity: stageIdentityImage,
    address: stageAddressImage,
    enhanced: stageEnhancedImage,
    corporate: stageCorporateImage,
    cfd: stageCFDImage,
  },
  logo,
  mobileAppQR,
  documents: {},
};
