import * as merchant from 'utils/merchant';
// eslint-disable-next-line import/no-named-default
import { default as en } from './en.json';

export const locales: any = {
  en,
};

const { locale } = merchant.getSettings();
const currentLocale = locale || 'en';

export const i18n = {
  locale: currentLocale,
  translations: locales[currentLocale],
};
